/* eslint-disable react/jsx-pascal-case */

import React from 'react';
import SEO from '../components/shared/SEO';

const IndexPage = () => (
  <>
    <SEO title='Home' />
    <div className='mt-4'>
      <h1 className='text-5xl font-thin sm:text-4xl'>This is the home page</h1>
    </div>
  </>
);

export default IndexPage;
